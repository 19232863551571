import {notification} from "antd";
import {appApi} from "services/api/api-instance";
import type {ListResponse} from "services/api/types";
import type {
  UserProfile,
  UserProfileBody,
  UserProfileMunicipality,
  UserProfileOrganization,
  UserProfileRegion,
  UserProfileOrganizationBody,
  UserProfileMunicipalityBody,
  UserProfileRegionBody,
  UsersRequest, GetOrganizationParams
} from "./types";

export const getCurrentUser = (): Promise<UserProfile> => {
  return appApi
  .get("/users_profile/current_profile/")
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка получения профиля"});
      throw error;
    });
};

export const createUserProfile = (body: UserProfileBody): Promise<UserProfile> => {
  return appApi
    .post("/users_profile/", body)
    .then(res => res.data)
    .catch(error => {
      switch (error.response.status) {
        case 409:
          notification.error({message: "Пользователь с указанным е-мэйлом уже существует"});
          break;
        default:
          notification.error({message: "Неизвестная ошибка при регистрации"});
      }
      throw error;
    });
};

export const getUserProfile = (userId: number): Promise<UserProfile> => {
  return appApi
    .get(`/users_profile/${userId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка получения профиля"});
      throw error;
    });
};

export const updateUserProfile = (userId: number, body: UserProfileBody): Promise<UserProfile> => {
  return appApi
    .put(`/users_profile/${userId}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Ошибка обновления профиля"});
      throw error;
    });
};

export const removeUserProfile = (userId: number): Promise<number> => {
  return appApi
    .delete(`/users_profile/${userId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось удалить пользователя"});
      throw error;
    });
};

export const getUsers = (payload: UsersRequest): Promise<ListResponse<UserProfile>> => {
  return appApi
    .get("/users_profile/", {params: payload})
    .then(({data, headers}) => {
      return {
        data,
        total: Number(headers["totalelements"]) || 0
      };
    })
    .catch(error => {
      notification.error({message: "Не удалось загрузить пользователей"});
      throw error;
    });
};

export const getRegions = (): Promise<UserProfileRegion[]> => {
  return appApi
    .get("/region/")
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось загрузить регионы"});
      throw error;
    });
};

export const createRegion = (body: UserProfileRegionBody): Promise<UserProfileRegion> => {
  return appApi
    .post("/region/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось создать регион"});
      throw error;
    });
};

export const removeRegion = (regionId: number): Promise<number> => {
  return appApi
    .delete(`/region/${regionId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось удалить регион"});
      throw error;
    });
};

export const updateRegion = (body: UserProfileRegion): Promise<UserProfileRegion> => {
  return appApi
    .put(`/region/${body.id}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось обновить регион"});
      throw error;
    });
};

export const passwordRecovery = (email: string, showNotification = true): Promise<void> => {
  return appApi
    .post(`/users_profile/password_recovery/${encodeURIComponent(email)}`)
    .then(() => {
      showNotification && notification.success({message: "На указанную почту отправлено письмо"});
    })
    .catch(error => {
      showNotification && notification.error({message: "Не удалось выполнить восстановление пароля"});
      throw error;
    });
};

export const getOrganizations = (params: GetOrganizationParams): Promise<ListResponse<UserProfileOrganization>> => {
  return appApi
    .get("/organization/", {
        params,
      }
    )
    .then(({data, headers}) => {
      return {
        data,
        total: Number(headers["totalelements"]) || 0
      };
    })
    .catch(error => {
      notification.error({message: "Не удалось загрузить организации"});
      throw error;
    });
};

export const removeOrganization = (organizationId: number): Promise<number> => {
  return appApi
    .delete(`/organization/${organizationId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось удалить организацию"});
      throw error;
    });
};

export const createOrganization = (body: UserProfileOrganizationBody): Promise<UserProfileOrganization> => {
  return appApi
    .post("/organization/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось создать организацию"});
      throw error;
    });
};

export const updateOrganization = (body: UserProfileOrganization): Promise<UserProfileOrganization> => {
  return appApi
    .put(`/organization/${body.id}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось обновить организацию"});
      throw error;
    });
};

export const getMunicipalities = (regionId: string): Promise<UserProfileMunicipality[]> => {
  return appApi
    .get(`/municipality/?regionId=${regionId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось загрузить районы"});
      throw error;
    });
};

export const removeMunicipality = (municipalityId: number): Promise<number> => {
  return appApi
    .delete(`/municipality/${municipalityId}`)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось удалить район"});
      throw error;
    });
};

export const createMunicipality = (body: UserProfileMunicipalityBody): Promise<UserProfileMunicipality> => {
  return appApi
    .post("/municipality/", body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось создать район"});
      throw error;
    });
};

export const updateMunicipality = (body: UserProfileMunicipality): Promise<UserProfileMunicipality> => {
  return appApi
    .put(`/municipality/${body.id}`, body)
    .then(res => res.data)
    .catch(error => {
      notification.error({message: "Не удалось обновить район"});
      throw error;
    });
};

export const changePassword = (password: string): Promise<void> => {
  return appApi
    .put(`/users_profile/change_password/${password}`)
    .then(() => {
      notification.success({message: "Пароль успешно изменен"});
    })
    .catch(error => {
      notification.error({message: "Не удалось изменить пароль"});
      throw error;
    });
};